import React from "react"

import Layout from "../components/layout"
import Snorkeling from "../components/snorkeling"
import Seo from "../components/seo"

const Content = ({ location }) => (
  <div className="body">
    <Seo
      pagetitle="ぽんこつコンテンツ"
      pagedesc="生きとし生けるぽんこつたちへの贈り物、ぽんこつコンテンツを心ゆくまで楽しもう。"
      pagepath={location.pathname}
      pageimg="snorkeling.png"
    />
    <Snorkeling />
    <Layout>
      <main className="content">
        <h2 className="content__title">ぽんこつコンテンツ（準備中）</h2>
        <div className="content__container">
          <p className="content__text">生きとし生けるぽんこつたちへの贈り物、ぽんこつコンテンツが続々と登場予定。続報を待て！</p>
        </div>
      </main>
    </Layout>
  </div>
)

export default Content