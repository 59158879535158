import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Parallax from "react-rellax"

const Snorkeling = () => (
  <div className="snorkeling">
    <p className="snorkeling__turtle"><StaticImage src="../images/snorkeling_turtle.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="snorkeling__image" /></p>
    <p className="snorkeling__coral1"><Parallax speed={-4}><StaticImage src="../images/snorkeling_coral1.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="snorkeling__image" /></Parallax></p>
    <p className="snorkeling__seahorse"><StaticImage src="../images/snorkeling_seahorse.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="snorkeling__image" /></p>
    <p className="snorkeling__coral2"><Parallax speed={-2}><StaticImage src="../images/snorkeling_coral2.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="snorkeling__image" /></Parallax></p>
    <p className="snorkeling__clownfish"><StaticImage src="../images/snorkeling_clownfish.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="snorkeling__image" /></p>
    <p className="snorkeling__angelfish"><StaticImage src="../images/snorkeling_angelfish.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="snorkeling__image" /></p>
    <p className="snorkeling__bubble1-2"><StaticImage src="../images/snorkeling_bubble1.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="snorkeling__image" /></p>
    <p className="snorkeling__bubble2-2"><StaticImage src="../images/snorkeling_bubble2.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="snorkeling__image" /></p>
    <p className="snorkeling__bear"><StaticImage src="../images/snorkeling_bear.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="snorkeling__image" /></p>
    <p className="snorkeling__bubble1"><StaticImage src="../images/snorkeling_bubble1.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="snorkeling__image" /></p>
    <p className="snorkeling__bubble2"><StaticImage src="../images/snorkeling_bubble2.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="snorkeling__image" /></p>
    <p className="snorkeling__filter"><StaticImage src="../images/snorkeling_filter.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="snorkeling__image" /></p>
  </div>
)

export default Snorkeling